import { Injectable } from '@angular/core';
import { Hub, Integrations, User, Plan } from '@shared/models';
import * as moment from 'moment';
import { AuthenticationService } from '@shared/services/authentication.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { KalturaService } from '@shared/services/kaltura.service';
import { switchMap } from 'rxjs/operators';
import { IBMService } from '@shared/services/ibm.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
@Injectable()
export class CurrentHubService {
  private hubSubject: BehaviorSubject<Hub | null> = new BehaviorSubject(null);
  hub: Hub;
  hubObservable: Observable<Hub> = this.hubSubject.asObservable();
  integrations: Integrations;
  plan: Plan;
  constructor(
    private _authService: AuthenticationService,
    private _kalturaService: KalturaService,
    private _ibmService: IBMService,
    private _courseAPIService: CoursesAPIService
  ) { }

  public async updateHubPlan(id: string) {
    return this.plan = await this._courseAPIService.getHubPlanInfo(id).toPromise();
  }
  public async updateHubIntegrations(hub: Hub) {
    if (this.plan && !this.plan.allowIntegrations) {
      return;
    }
    this.integrations = await this._courseAPIService.getHubIntegrationsInfo(hub.id).toPromise();
    // IBM
    if (this.integrations.ibm) {
      this._ibmService
        .resetConnection(hub.id)
        .pipe(switchMap(() => this._ibmService.checkAccount(hub.id)))
        .subscribe(() => (this.integrations.ibm = true));
    }
    // Kaltura
    if (this.integrations.kaltura) {
      const user = await this._authService.getWondaUser();
      this._kalturaService
        .startKalturaSession(hub.id, user)
        .subscribe(session => (this.integrations.kaltura = session));
    }
    //Todo: LTI
    return;
  }
  async setCurrentHub(hub: Hub): Promise<Hub> {
    const hubIdChanged = !this.hub || this.hub.id !== hub.id;
    this.hub = hub;
    if (hubIdChanged) {
      await this.updateHubPlan(hub.id);
      await this.updateHubIntegrations(hub);
    }
    this.hubSubject.next(hub);
    return hub;
  }

  public isAdminOfCurrentHub(user: User): boolean {
    return this.isAdminOfHub(user, this.hub.id);
  }
  public isAdminOfHub(user: User, hub_id: string): boolean {
    try {
      const userRole = user.Hubs.find(hub => {
        return hub.id === hub_id;
      }).HubsUsers.role;
      return userRole === "admin";
    } catch (err) {
      return false;
    }
  }

  public planTimeLeft(): number {
    const endDate = moment(this.hub.plan_end_date);
    return Math.round(moment.duration(endDate.diff(moment())).asDays());
  }
  public planExpiration(): Date {
    return this.hub.plan_end_date;
  }
  public isFree(): boolean {
    return this.hub.plan === 'free';
  }
  public isPaid(): boolean {
    return this.hub.plan !== 'free';
  }
  public getHubTitle(): string {
    return this.hub.title;
  }
  public getHubURLTitle(): string {
    return this.hub.urlTitle;
  }
  public isLTIEnabled(): boolean {
    return this.hub.lti_enabled;
  }
  public isAssessmentEnabled(): boolean {
    return this.hub.properties.isAssessmentEnabled;
  }
  public isVersioningEnabled(): boolean {
    return this.hub.properties.enableVersioning;
  }
  public isTranslationEnabled(): boolean {
    return this.hub.properties.enableTranslation;
  }
  public isLTIReturnURLTypeLinkEnabled(): boolean {
    return this.hub.properties.enableLTIReturnURLTypeLink;
  }
}
