import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CurrentHubService } from '@app/shared/services/currentHub.service';
import * as moment from 'moment';
import { DurationConverter } from "./misc/duration-converter.pipe";
import { formatNumber, formatPercent } from '@angular/common';
import { Course } from '@shared/models';
export enum AnalyticsTabs {
  Overview,
  Visitors,
  Content,
  Sessions,
  Quizzes,
  Assessment
}
interface AnalyticsQueryOptions {
  dateFrom?: Date;
  dateTo?: Date;
  userName?: string;
  offset?: number;
  limit?: number;
  criteria?: string;
}
export interface StatCard {
  icon: string;
  label: string;
  sublabel: string;
}
export interface GeneralStats {
  sessionCount: number;
  totalDuration: number;
  avgDuration: number;
  avgScore: number;
  usersCount: number;
  anonymousUsersCount: number;
  completedRatio: number;
  passedRatio: number;
  avgSessionPerVisitor: number;
  avgAIVoiceMinutes: number;
  totalAIVoiceMinutes: number;
};
export interface SessionData {
  _id: string;
  date: Date;
  actor_name: string;
  multisession: boolean;
  score: number | null;
  duration: number;
  completed: boolean;
  passed: boolean;
  sceneViews: number;
}
export interface PopularExperience {
  activity: string,
  sessionCount: number,
  id: string
}
export const chartColorPalette = ["#45A6B9", "#35BCBC", "#4DD1B1", "#7EE29C", "#B8F084", "#F9F871"]

@Injectable()
export class AnalyticsService {
  private credentials = {
    client_key: '',
    client_secret: ''
  };
  constructor(
    private _http: HttpClient,
    private _currentHubService: CurrentHubService
  ) {
    this._currentHubService.hubObservable.pipe(
      filter(hub => hub != null)
    ).subscribe(hub => {
      const credentials = {
        client_key: hub.properties.lrs_store.api.basic_key,
        client_secret: hub.properties.lrs_store.api.basic_secret,
        lrs_store: hub.properties.lrs_store.lrs_id,
        lrs_organisation: hub.properties.lrs_store.organisation
      };
      this.credentials = credentials;
    })
  }

  /**
   *  Analytics API
   */
  public getCourseStats(courseId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${courseId}`, body);
  }
  public getCourseTraffic(courseId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post<any>(environment.analyticsServiceUrl + `/analytics/experience/${courseId}/traffic`, body);
  }
  public getSessions(courseId: string, queryOptions: AnalyticsQueryOptions, format = 'json'): Observable<SessionData[] | string> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    let options;
    if (format !== 'json') {
      options = {
        responseType: 'text' as const
      }
    }
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${courseId}/sessions?format=${format}`, body, options);
  }
  public getLaunchByBrowser(courseId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${courseId}/browsers`, body);
  }
  public getLaunchByOS(courseId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${courseId}/os`, body);
  }
  public getLaunchBySource(courseId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${courseId}/sources`, body);
  }
  public getSessionLog(sessionId: string, format = 'json'): Observable<any> {
    let options;
    if (format !== 'json') {
      options = {
        responseType: 'text' as const
      }
    }
    return this._http.post(environment.analyticsServiceUrl + `/analytics/session/${sessionId}?format=${format}`, this.credentials, options);
  }
  public getQuizResults(quizId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/quiz/${quizId}`, body);
  }
  public getLearnspaceStats(lsId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/learnspace/${lsId}`, body);
  }
  // public getLearnspaceActiveExperiences(lsId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
  //   const body = {
  //     ...this.credentials,
  //     queryOptions: queryOptions
  //   };
  //   return this._http.post(environment.analyticsServiceUrl + `/analytics/learnspace/${lsId}/activeexperiences`, body);
  // }
  public getLearnspacePopularExperiences(lsId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/learnspace/${lsId}/popularexperiences`, body);
  }
  public getSceneStats(experienceId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${experienceId}/scenes`, body);
  }
  public getContentsStats(experienceId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${experienceId}/contents`, body);
  }
  public getHubStats(hubId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/hub/${hubId}`, body);
  }

  public getHubAIStats(hubId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/hub/${hubId}/aiusage`, body);
  }

  // public getHubActiveExperiences(hubId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
  //   const body = {
  //     ...this.credentials,
  //     queryOptions: queryOptions
  //   };
  //   return this._http.post(environment.analyticsServiceUrl + `/analytics/learnspace/${hubId}/activeexperiences`, body);
  // }
  public getHubSessionTraffic(hubId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/hub/${hubId}/sessiontraffic`, body);
  }
  public getHubVisitorTraffic(hubId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/hub/${hubId}/visitortraffic`, body);
  }
  public getHubGuestTraffic(hubId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/hub/${hubId}/guesttraffic`, body);
  }
  public getHubPopularExperiences(hubId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/hub/${hubId}/popularexperiences`, body);
  }

  public getHubSessions(hubId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/hub/${hubId}/sessions`, body);
  }

  public getAnnotationsStats(annotationIds: string[], queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      annotations: annotationIds,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/annotations`, body);
  }

  public getVisitors(experienceId: string, queryOptions: AnalyticsQueryOptions, format = 'json'): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    let options;
    if(format!== 'json'){
      options = {
        responseType: 'text' as const
      }
    }
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${experienceId}/users?format=${format}`, body, options);
  }

  public getSceneViewsStats(experienceId: string, queryOptions: AnalyticsQueryOptions): Observable<any> {
    const body = {
      ...this.credentials,
      queryOptions: queryOptions
    };
    return this._http.post(environment.analyticsServiceUrl + `/analytics/experience/${experienceId}/sceneviews`, body);
  }

}

/*
  Analytics Helper functions
*/
export function getDefaultDateRange(): { date_from: Date, date_to: Date } {
  let todayMoment, endOfDay;
  todayMoment = moment();
  endOfDay = todayMoment.clone();
  return {
    date_from: endOfDay.clone().subtract(1, 'years').toDate(),
    date_to: endOfDay.toDate()
  }


}
export function getGeneralStatCards(stats: GeneralStats, showCompletion: boolean, showScoring: boolean): StatCard[] {
  if (!stats) stats = {
    sessionCount: 0,
    totalDuration: 0,
    avgDuration: 0,
    avgScore: 0,
    usersCount: 0,
    anonymousUsersCount: 0,
    completedRatio: 0,
    passedRatio: 0,
    avgSessionPerVisitor: 0,
    avgAIVoiceMinutes: 0, 
    totalAIVoiceMinutes: 0
  }
  const visitorStatCard = {
    icon: 'fa-users',
    label: `<span class="figure">${stats.usersCount || 0} </span>&nbsp;<span class="label">${(!isNaN(stats.usersCount) && stats.usersCount) > 1 ? ' Visitors' : ' Visitor'}</span>`,
    sublabel: 'including ' + (stats.anonymousUsersCount || 0) + ' guests'
  }
  const sessionStatCard = {
    icon: 'fa-eye',
    label: `<span class="figure"> ${(stats.sessionCount || 0)}</span>&nbsp;<span class="label">${(!isNaN(stats.sessionCount) && stats.sessionCount > 1 ? 'Sessions' : 'Session')}</span>`,
    sublabel: `${formatNumber(stats.avgSessionPerVisitor || 0, 'en-US', '1.1-1')} sessions per visitor'`
  }
  const durationStatCard = {
    icon: 'fa-clock',
    label: `<span class="figure figure-time">${DurationConverter.prototype.transform(stats.avgDuration, "mm")}</span> <span class="label">/ session</span>`,
    sublabel: ``
  }
  if (showCompletion) {
    durationStatCard.sublabel = `${formatPercent(stats.completedRatio || 0, 'en-US')} completed`
  }
  let statcards = [visitorStatCard, sessionStatCard, durationStatCard];

  if (showScoring) {
    statcards.push({
      icon: 'fa-circle-check',
      label: `<span class="figure">${formatPercent(stats.passedRatio || 0, 'en-US')}</span>&nbsp;<span class="label">passed</span>`,
      sublabel: `Average score of ${formatNumber(stats.avgScore || 0, 'en-US', '1.1-1')}`
    })
  }
  if (stats && stats.totalAIVoiceMinutes > 0) {
    const aiStatCard = {
      icon: 'fa-message',
      label: `<span class="figure"> ${Math.ceil((stats?.totalAIVoiceMinutes || 0))}</span>&nbsp;<span class="label"> AI Voice Min </span>`,
      sublabel: `${Math.ceil((stats?.avgAIVoiceMinutes || 0))} min/session`
    }
    statcards.push(aiStatCard)
  }
  return statcards;
}

export function isCompletionEnabled(course: Course): boolean {
  return course && course.information && course.information.trackingEnabled;
}
export function isScoringEnabled(course: Course): boolean {
  return course && course.information && course.information.scoringEnabled;
}

export function getStartOfMonth(): Date {
  return moment().startOf('month').toDate();
}

