import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssessmentResult } from '@shared/models';

@Injectable()
export class AssessmentService {
  constructor(
    private _http: HttpClient,
  ) {}

  public getAssessments(courseId: string, limit: number = 10): Observable<AssessmentResult[]> {
    return this._http.get<AssessmentResult[]>(environment.coursesApiUrl + `/api/assessments?courseId=${courseId}&limit=${limit}`);
  };

  public generate(sessionId: string, courseId: string): Observable<any> {
    const body = { sessionId, courseId };
    return this._http.post<any>(environment.coursesApiUrl + '/api/assessments', body);
  };

  public getAssessmentsExportDataByCourseId(courseId: string, limit: number = 10){
    const url = environment.coursesApiUrl + `/api/assessments/export/courses/${courseId}`;
    window.open(url);
  }
  
  public getAssessmentExportDataBySessionId(courseId: string, sessionId: string) {
    const url = environment.coursesApiUrl + `/api/assessments/export/courses/${courseId}/sessions/${sessionId}`;
    window.open(url)
  }
}
