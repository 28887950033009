import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';
import { User as WondaUser } from '@shared/models/user.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject, from, merge } from 'rxjs';
import {
  catchError,
  switchMap,
  tap,
  map,
  finalize,
  share,
  first,
  skip,
  take
} from 'rxjs/operators';
import { InternalAnalyticsService } from '@shared/services/internalAnalytics.service';
import { Invitation, InvitationType } from '@shared/models';

@Injectable()
export class AuthenticationService {
  public user: firebase.User;
  public userState: Observable<WondaUser>;
  public redirectURL: string;
  public redirectOuter: string;
  public invitation: Invitation;
  public messageSent: string;
  public wondaUser: WondaUser;
  private refresh = new BehaviorSubject<string>(null);
  private refreshObs = this.refresh.asObservable();
  private firstNav = true;

  constructor(
    private _cookieService: CookieService,
    public _fireAuth: AngularFireAuth,
    private _http: HttpClient,
    private _router: Router,
    private zone: NgZone,
    private _internalAnalyticsService: InternalAnalyticsService
  ) {

    this.userState = merge(this._fireAuth.authState, this.refreshObs).pipe(
      skip(1), //first event is always null from initialization I guest
      switchMap((event) => {
        return this.getOrRefreshToken();
      }),
      switchMap((event) => {
        if (event) {
          return this.getWondaUser();
        } else {
          this.wondaUser = null;
          return of(null)
        }
      }),
      catchError(() => {
        return of(null);
      }),
      finalize(() => {
        console.error("this.userState should never finalize");
      }),
      share()
    );
  }

  private throwWrongLoginMethodError(methods: String[]) {
    const firstMethod = methods[0];
    let renamedMethod;
    switch (firstMethod) {
      case 'google.com': {
        renamedMethod = 'Google';
        break;
      }
      case 'microsoft.com': {
        renamedMethod = 'Microsoft';
        break;
      }
      case 'password': {
        renamedMethod = 'email + password';
        break;
      }
      default: {
        throw new Error(`Your login is broken, please contact the support. User methods: ${methods}`);
      }
    }
    const msg = `You have created an account with this email by using the ${renamedMethod} login option. Please use the same method to log in.`
    throw new Error(msg);
  }
  /**
   * Firebase auth
   */
  public logout(): void {
    this._router.navigate(['/login']);
    this._fireAuth.auth.signOut().then(() => {
      this._http
        .get<{ redirectTo?: string }>(
          environment.coursesApiUrl + '/api/users/logout'
        )
        .subscribe(res => {
          this._cookieService.delete(
            'firebaseToken',
            '/',
            environment.cookieSubdomain
          );
          this.refreshAuthState(); // for some reason authState is not triggered at signout
          this._internalAnalyticsService.disconnectIntercom();
          if(res.redirectTo) {
            window.location.href = res.redirectTo;
          }
        });
    });
  }
  public async isAuth(): Promise<boolean> {
    let currentUser = this.getCurrentUser();

    if (currentUser == null) {
      await this.customTokenLogin();
      if (this.firstNav) {
        this.firstNav = false;
        await this.userState.pipe(take(1)).toPromise(); ///at first nav wait for userState to avoid unwanted redirect to login and then to page
      }
      currentUser = this.getCurrentUser();
    }
    return currentUser != null;
  }

  public isAdmin(): boolean {
    return this.wondaUser.role === 'admin';
  }
  public getCurrentUser(): firebase.User {
    return this._fireAuth.auth.currentUser;
  }

  public resetCurrentUserPassword(email: string): any {
    return this._fireAuth.auth.sendPasswordResetEmail(email);
  }

  public getWondaUser(): Promise<WondaUser> {
    if (!this.wondaUser && this.isAuth()) { //firebase user is defined but not wonda user
      return this.getMe().toPromise();
    }
    else {
      return Promise.resolve(this.wondaUser);
    }
  }

  public getOrRefreshToken(): Observable<string> {
    if (!this.getCurrentUser()) {
      return of(null);
    }
    return from(this.getCurrentUser().getIdToken()).pipe(
      map(token => {
        if (token !== this._cookieService.get('firebaseToken')) {
          this._cookieService.set(
            'firebaseToken',
            token,
            14,
            '/',
            environment.cookieSubdomain
          );
        }
        return token;
      })
    );
  }

  public mailLogin(mail: string, password: string): Observable<any> {
    return from(
      this._fireAuth.auth.signInWithEmailAndPassword(mail, password)
    ).pipe(
      switchMap(() => this.storeUserInfo()),
      catchError(err => {
        return this._fireAuth.auth
          .fetchSignInMethodsForEmail(mail)
          .then(methods => {
            if (
              methods.length >= 1 &&
              methods.find(m => m === 'password') === undefined
            ) {
              this.throwWrongLoginMethodError(methods);
            } else {
              return Promise.reject(err);
            }
          });
      })
    );
  }

  private createAuthProvider(provider: string): auth.AuthProvider {
    switch (provider) {
      case 'google':
        return new auth.GoogleAuthProvider();
      case 'microsoft': {
        const microsoftProvider = new auth.OAuthProvider('microsoft.com');
        // microsoftProvider.setCustomParameters({prompt: 'consent'});
        return microsoftProvider;
      }
    }
  }

  public quickLogin(provider: string, allowCreateAccount: boolean): Observable<any> {
    const authProvider = this.createAuthProvider(provider)
    return from(this._fireAuth.auth.signInWithPopup(authProvider)).pipe(
      switchMap(signInSuccessResponse => {
        if (!allowCreateAccount && signInSuccessResponse.additionalUserInfo.isNewUser) {
          // so far no way to prevent sign up with firebase https://github.com/firebase/firebaseui-web/issues/99
          return this._fireAuth.auth.currentUser.delete().then(() => {
            throw new Error('No account found');
          });
        }
        return this.storeUserInfo();
      }),
      catchError(err => {
        if (err.code === 'auth/account-exists-with-different-credential') {
          return this._fireAuth.auth
            .fetchSignInMethodsForEmail(err.email)
            .then(methods => {
              this.throwWrongLoginMethodError(methods);
            });
        } else if (err.code === 'auth/popup-closed-by-user') {
          return of(null);
        } else {
          throw err;
        }
      })
    );
  }

  public customTokenLogin(): Promise<any> {
    const customToken = this._cookieService.get('firebaseCustomToken');
    if (customToken) {
      this._cookieService.delete(
        'firebaseCustomToken',
        '/',
        environment.cookieSubdomain
      );
      return this._fireAuth.auth.signInWithCustomToken(customToken)
        .catch(err => {
          console.error("customTokenLogin:: signInWithCustomToken error", err);
          return null;
        });
    } else {
      return Promise.resolve(null);
    }
  }

  public storeUserInfo(): Observable<any> {
    return from(this._fireAuth.auth.currentUser.getIdToken(true)).pipe(
      tap(idToken =>
        this._cookieService.set(
          'firebaseToken',
          idToken,
          7,
          '/',
          environment.cookieSubdomain
        )
      ),
      switchMap(() => {
        return this.getOrCreateWondaUser();
      }),
      tap(() => {
        this.afterLoginRedirect();
      })
    );
  }
  public afterLoginRedirect(): void {
    // Redirect to outer locations (ex: classroom)
    if (this.redirectOuter) {
      window.location.href = this.redirectOuter;
    }
    this.getWondaUser().then(user => {
      const shouldCreateHub = !this.invitation && user.Hubs && user.Hubs.length === 0;
      // Redirect to create hub
      if (shouldCreateHub) {
        this.zone.run(() => {
          this._router.navigate(['/createHub']);
        })
      } else {
        // Redirect to prompted page, or root if nothing specified
        const redirectTo = this.redirectURL && this.redirectURL !== '/login' ? this.redirectURL : '/';
        this.zone.run(() => {
          this._router.navigate([redirectTo], {
            queryParamsHandling: 'preserve'
          });
        });
      }
    });
  }

  public reloadWondaUser(forceReloadState = true): void {
    this.getMe().subscribe(() => {
      if (forceReloadState) {
        this.refreshAuthState();
      }
    });
  }

  public refreshAuthState(): void {
    this.refresh.next('tick');
  }

  public getOrCreateWondaUser(): Observable<WondaUser> {
    return this.logInWonda().pipe(
      switchMap(resp => {
        if (resp.wonda) {
          return of(resp.wonda);
        } else {
          return this.createWondaUser(resp.firebase.uid);
        }
      }),
      switchMap(() => {
        return this.getMe(); //resp does not contain hubs list
      })
    );
  }

  /**
   *  API calls
   */

  public getMe(): Observable<WondaUser> {
    return this._http.get<WondaUser>(
      environment.coursesApiUrl + '/api/users/me'
    ).pipe(tap(user => this.wondaUser = user));
  }
  public logInWonda(): Observable<{ wonda: any; firebase: any }> {
    return this._http.get<{ wonda: any; firebase: any }>(
      environment.coursesApiUrl + '/api/users/login'
    );
  }

  public ssoLogin(hubId: string, redirect?: string) {
    let redirectTo;
    if (redirect) {
      redirectTo = redirect
    } else if (this.redirectOuter) {
      redirectTo = this.redirectOuter;
    } else if (this.invitation) {
      redirectTo = this.invitation.path;
    } else {
      redirectTo = window.location.origin;
    }
    const redirectParam = `?redirect=${encodeURIComponent(redirectTo)}`;
    window.location.href = `${environment.coursesApiUrl}/api/auth/saml/${hubId}/login${redirectParam}`
  }

  // called on firebase provider method
  public createWondaUser(id: string): Observable<any> {
    return this._http.post(
      environment.coursesApiUrl + '/api/users/fromFirebase/' + id,
      {}
    );
  }

  // called on email/password method
  public createUser(user: any): Observable<any> {
    return this._http.post(environment.coursesApiUrl + '/api/users', user);
  }
}
